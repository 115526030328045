export function setCookie(cname, cvalue, exhours) {
  try {
    var d = new Date();
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + '=' + JSON.stringify(cvalue) + ';' + expires + ';path=/;SameSite=Strict;Secure';
  } catch (err) {
    return null;
  }
}

export function getCookie(cname) {
  try {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return JSON.parse(c.substring(name.length, c.length));
      }
    }
  } catch(err) {
    // 
  }
  return "";
}

export function hasCookie(cookie_name) {
  var cookie_value = getCookie(cookie_name);
  if (cookie_value !== "") {
    return true;
  } else {
    return false;
  }
}